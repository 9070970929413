import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fuse from "fuse.js";

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"
import PageSearchBox from "../../components/boxes/PageSearchBox"
import VideoPreview from "../../components/VideoPreview"
import PageHeader from "../../components/layout/PageHeader"
import Grid3Columns from "../../components/layout/Grid3Columns";

const WhyVideoPage = () => {
    const [search, setSearch] = useState(null);

    const data = useStaticQuery(graphql`
    {
        allMysqlVideoBlogPosts(sort: {fields: added_date, order: DESC}) {
            edges {
                video: node {
                    id
                    name
                    video_url
                    title
                    summary
                    image_url
                    added_date
                }
            }
        }
    }
  `);

  const [videos, setVideos] = useState(data.allMysqlVideoBlogPosts.edges)

  useEffect(
    () => {
      var options = {
        shouldSort: true,
        tokenize: false,
        findAllMatches: false,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: [
          "video.name",
          "video.title",
          "video.summary"
        ]
      };

      if (search != null && search.length > 0) {
        const fuse = new Fuse(data.allMysqlVideoBlogPosts.edges, options); // "list" is the item array
        const result = fuse.search(search);

        setVideos(result);
      }
      else {
        setVideos(data.allMysqlVideoBlogPosts.edges)
      }
    }, 
    [search]
  );

  return (
    <>
      <SEO page="photos" title="Videos of our Sign Products" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why/", title: "Why SignMonkey"},
        { url: null, title: "Videos" }
      ]}>

        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <PageHeader 
            title="Videos" 
            subtitle="Learn about signs and our products"
          />
          <PageSearchBox 
            search={search}
            onSearch={(keyword) => setSearch(keyword)}
            />
        </div>

        { videos.length > 0 
          ?
            <Grid3Columns>
            { videos.map((item, index) => (
                <div key={item.video.id}>
                  <VideoPreview 
                      title={item.video.title}
                      summary={item.video.description}
                      video_url={"https://www.youtube.com/embed/" + item.video.video}
                      image_url={item.video.image_url} 
                      page_url={"/why/videos/" + item.video.name}
                      size="large"
                  />
                </div>
            ))}
            </Grid3Columns>
          :
            <p>No Videos Found</p>
        }
      </Page>
    </>
  )
}

export default WhyVideoPage
