import React, { useState } from "react"
import styled from 'styled-components'
import { isMobile } from 'react-device-detect';

import { faSearch } from "@fortawesome/free-solid-svg-icons"

import TextField from "../fields/TextField"
import Button from "../buttons/Button"

const Box = styled.div`
    margin-bottom: 10px;

    input {
      max-width: ${isMobile ? 400:200}px;
    }
    button { 
        border-radius: 3px;

        &[disabled] {
            opacity: .8;
            cursor: not-allowed;
        }      
    }
`

const PageSearchBox = (props) => {
  const [keyword, setKeyword] = useState(props.search);

  function onChange(e) {
    setKeyword(e.target.value);
  }
  function onSearch() {
    props.onSearch(keyword);
  }
  function onReset() {
    setKeyword("");
    props.onSearch(null);
  }

  return (
    <>
      <Box>
          <TextField 
              value={keyword} 
              label={props.label || "Search"}
              onChange={onChange} 
              onEnter={onSearch}
          >
            <div>
              <Button 
                size="small"
                disabled={!keyword || keyword.length === 0} 
                icon={faSearch}
                busy={props.searching}
                onClick={onSearch}
              />
              { (props.search && props.search.length > 0) && 
                <Button 
                  size="small"
                  label="Reset"
                  color="grey"
                  onClick={onReset}
                />
              }
            </div>
          </TextField>
      </Box>
    </>
  )
}

export default PageSearchBox
