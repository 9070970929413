import React from "react"
import styled from "styled-components"

import { devices } from "../../settings"

const Grid = styled.div`
  display: flex;
  flex-flow: wrap;

  > div {
      flex-basis: 33.3%;
  }

  @media ${devices.mobile} {
      display: block;
  }
`

export default (props) => {
  return (
    <Grid align={props.align} padding={props.padding} left={props.left} right={props.right}>
        {props.children}
    </Grid>
  )
}